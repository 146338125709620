<template>
<sa-content></sa-content>
</template>

<script>
const saContent = () => import( /* webpackChunkName: "Content" */ '@/components/Content.vue')
export default {
  components: {
    saContent,
  }
}
</script>
